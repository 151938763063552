<template>
	<div class="md-layout">
		<div
			class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 mobile-none"
		>
			<md-card>
				<md-card-header>
					<div class="card-icon card-blue">
						<md-icon>playlist_add</md-icon>
					</div>
					<h4 class="title"></h4>
				</md-card-header>

				<div
					class="md-layout-item md-xs-size-100 md-small-size-40 md-medium-size-40 md-large-size-40 md-xlarge-size-40 mobile-none"
					v-show="progressbar || progressUpload > 0"
				>
					<label for="">{{ progressMsg }}</label> &nbsp;
					<img
						src="@/assets/load.gif"
						style="width: 16px;"
						v-show="requestStatus"
					/>
					<el-progress :percentage="progressUpload"></el-progress>
				</div>

				<md-card-content>
					<md-button
						class="md-success"
						@click="newDirectory()"
						:disabled="requestStatus"
						><i class="fas fa-folder"></i> Novo Diretório</md-button
					>
					<md-button
						class="md-info"
						@click="newFile()"
						:disabled="requestStatus"
						><i class="fas fa-upload"></i> Enviar Arquivos</md-button
					>
					<md-table md-card>
						<md-table-row>
							<md-table-head>Diretório/Arquivos</md-table-head>
							<md-table-head>Total de músicas</md-table-head>
							<md-table-head>Data Modificação</md-table-head>
							<md-table-head>Ações</md-table-head>
						</md-table-row>

						<md-table-row
							slot="md-table-row"
							v-for="(item, index) in dataDirectory"
							:key="index"
							class="row-folder"
						>
							<md-table-cell v-if="item.folder">
								<i class="fas fa-folder-open"></i> {{ item.folder }}
							</md-table-cell>
							<md-table-cell v-else>
								{{ item.mp3Name }}
							</md-table-cell>
							<md-table-cell>
								{{ item.total ? item.total : 0 }}
							</md-table-cell>
							<md-table-cell v-if="item.date">
								{{ new Date(item.date).toLocaleString("pt-BR") }}
							</md-table-cell>
							<md-table-cell v-else>
								{{ new Date().toLocaleString("pt-BR") }}
							</md-table-cell>
							<md-table-cell>
								<div class="container-btn-edit">
									<div class="row-btn-edit-playlist">
										<el-button
											icon="el-icon-edit"
											circle
											@click="editFile(item, index)"
										></el-button>
									</div>
									<div class="row-btn-edit-playlist">
										<el-button
											icon="el-icon-delete"
											circle
											@click="deleteMusicAndFolder(item, index)"
										></el-button>
									</div>
								</div>
								<!-- <div>
                                    <button class="btn-actions" @click="editFile(item, index)"><i class="fas fa-edit"></i></button>
                                    <button class="btn-actions" id="btn-action-times" @click="deleteMusicAndFolder(item, index)"><i class="far fa-times-circle"></i></button>
                                </div>                                 -->
							</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
			</md-card>
		</div>

		<!-- EL DIALOG - FOLDER -->
		<el-dialog :visible.sync="dialogOpenDirectory" width="60%">
			<div>
				<h3 style="color:#409EFF; font-weight:bold">EDITAR MÚSICAS</h3>
				<div>
					<h4>
						1. Para adicionar novas músicas clique no botão adicionar músicas
						logo abaixo. <br />
						2. Para atualizar vá para o próximo passo e prossiga até finalizar.
						<br />
						3. Para remover clique no "X" e prossiga com os passos até
						finalizar.<br />
						4. Só são aceitos músicas no formato .MP3
						<br />
					</h4>
				</div>
			</div>
			<!-- <span>Adicione os arquivos abaixo e clique em iniciar upload.</span> <br> -->
			<el-upload
				ref="uploadDirectory"
				class="upload-demo"
				action="#"
				accept=".mp3"
				multiple
				:auto-upload="false"
				:on-change="getFileToUpload"
			>
				<el-button type="success">Adicionar músicas</el-button>
				<!-- <div slot="tip" class="el-upload__tip">jpg/png files with a size less than 500kb</div> -->
			</el-upload>

			<el-table :data="arrFiles">
				<el-table-column
					property="mp3Name"
					label="Nome do arquivo"
				></el-table-column>
				<el-table-column
					property="title"
					label="Nome da música"
				></el-table-column>
				<el-table-column
					property="created"
					label="Data Modificação"
				></el-table-column>
				<el-table-column fixed="right" label="Remover">
					<template slot-scope="scope">
						<el-button
							@click="deleteItemInFolder(arrFiles[scope.$index], scope.$index)"
							icon="el-icon-close"
							circle
							size="mini"
						></el-button>
						<!-- <button class="btn-actions" id="btn-action-times" @click="deleteItemInFolder(arrFiles[scope.$index], scope.$index)"><i class="far fa-times-circle"></i></button> -->
					</template>
				</el-table-column>
			</el-table>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogOpenDirectory = false">Cancelar</el-button>
				<el-button type="primary" @click="step(0)"
					><i class="fas fa-arrow-right"></i> Próximo passo</el-button
				>
			</span>
		</el-dialog>

		<!-- EL DIALOG - WITHOUT FOLDER -->
		<el-dialog :visible.sync="dialogOpenFile" width="60%">
			<div>
				<h3 style="color:#409EFF; font-weight:bold">CARREGAR MÚSICAS</h3>
				<div>
					<h4>
						1. Para adicionar novas músicas clique no botão adicionar músicas
						logo abaixo. <br />
						2. Para atualizar vá para o próximo passo e prossiga até finalizar.
						<br />
						3. Para remover clique no "X" e prossiga com os passos até
						finalizar.<br />
						4. Só são aceitos músicas no formato .MP3
						<br />
					</h4>
				</div>
			</div>
			<!-- <span>Adicione os arquivos abaixo e clique em iniciar upload.</span> <br> -->
			<el-upload
				ref="upload"
				class="upload-demo"
				accept=".mp3"
				action="#"
				multiple
				:auto-upload="false"
				:on-change="getFileToUpload"
			>
				<el-button type="success">Adicionar músicas</el-button>
			</el-upload>

			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogOpenFile = false">Cancelar</el-button>
				<el-button
					type="primary"
					@click="step(0)"
					:disabled="this.arrFilesUpload.length === 0"
					><i class="fas fa-arrow-right"></i> Próximo passo</el-button
				>
			</span>
		</el-dialog>

		<!-- STEPS CREATE-->
		<el-dialog title="" :visible.sync="panelSteps">
			<div
				class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
			>
				<el-steps :active="active" simple finish-status="success" :space="200">
					<el-step title="Metadata" icon="fas fa-music"></el-step>
					<el-step title="Validação" icon="fas fa-edit"></el-step>
				</el-steps>

				<div v-show="active == 0" class="order-list">
					<div>
						<el-table :data="dataId3">
							<el-table-column label="Nome do arquivo">
								<template slot-scope="scope">
									<el-input
										size="mini"
										v-model="scope.row.mp3Name"
										:disabled="true"
									></el-input>
								</template>
							</el-table-column>
							<el-table-column label="Nome da música">
								<template slot-scope="scope">
									<el-input size="mini" v-model="scope.row.title"></el-input>
								</template>
							</el-table-column>
							<el-table-column label="Nome do artista">
								<template slot-scope="scope">
									<el-input size="mini" v-model="scope.row.artist"></el-input>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>

				<div v-show="active == 1" class="order-list">
					<div>
						<el-table :data="dataId3">
							<el-table-column prop="mp3Name" label="Nome do arquivo">
							</el-table-column>
							<el-table-column prop="title" label="Nome da música">
							</el-table-column>
							<el-table-column prop="artist" label="Nome do artista">
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div
					class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50"
				>
					<el-button
						v-show="active == 1"
						style="margin-top: 12px;"
						@click="active = active - 1"
						>Voltar</el-button
					>
					<el-button
						v-show="active != 1"
						style="margin-top: 12px;"
						@click="next"
						:disabled="blockMetadata"
						>Próximo passo</el-button
					>
					<el-button
						v-show="active == 1"
						style="margin-top: 12px;"
						@click="uploadFiles(dataId3)"
						>Salvar</el-button
					>
				</div>
			</div>
		</el-dialog>

		<!-- STEPS UPDATE FILE -->
		<el-dialog :visible.sync="panelStepsUpdate" width="60%">
			<div
				class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
			>
				<el-steps :active="active" simple finish-status="success" :space="200">
					<el-step title="Metadata" icon="fas fa-music"></el-step>
					<el-step title="Validação" icon="fas fa-edit"></el-step>
				</el-steps>

				<div v-show="active == 0" class="order-list">
					<div>
						<el-table :data="dataId3">
							<el-table-column label="Nome do arquivo">
								<template slot-scope="scope">
									<el-input
										size="mini"
										v-model="scope.row.mp3Name"
										:disabled="true"
									></el-input>
								</template>
							</el-table-column>
							<el-table-column label="Nome da música">
								<template slot-scope="scope">
									<el-input size="mini" v-model="scope.row.title"></el-input>
								</template>
							</el-table-column>
							<el-table-column label="Nome do artista">
								<template slot-scope="scope">
									<el-input size="mini" v-model="scope.row.artist"></el-input>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>

				<div v-show="active == 1" class="order-list">
					<div>
						<el-table :data="dataId3">
							<el-table-column prop="mp3Name" label="Nome do arquivo">
							</el-table-column>
							<el-table-column prop="title" label="Nome da música">
							</el-table-column>
							<el-table-column prop="artist" label="Nome do artista">
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div
					class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50"
				>
					<el-button
						v-show="active == 1"
						style="margin-top: 12px;"
						@click="active = active - 1"
						>Voltar</el-button
					>
					<el-button
						v-show="active != 1"
						style="margin-top: 12px;"
						@click="next"
						:disabled="blockMetadata"
						>Próximo passo</el-button
					>
					<el-button
						v-show="active == 1"
						style="margin-top: 12px;"
						@click="updateFiles(dataId3)"
						>Atualizar</el-button
					>
				</div>
			</div>
		</el-dialog>

		<!-- <div v-show="!cloudCredentials" class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
            <md-card>
                <md-card-header>
                    <div class="card-icon card-blue">
                        <md-icon>playlist_add</md-icon>
                    </div>
                    <h4 class="title"></h4>
                </md-card-header>

                <md-card-content>
                    Sua rádio não tem as credenciais necessárias pra usar este serviço.
                    Qualquer dúvida entre em contato conosco pelo email <a href="mailto:suporte@mobradio.com.br">suporte@mobradio.com.br</a>.
                </md-card-content>
            </md-card>
        </div> -->

		<div
			class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 desktop-on"
		>
			Serviço disponível apenas para Desktop. Qualquer dúvida entre em contato
			conosco pelo email
			<a href="mailto:suporte@mobradio.com.br">suporte@mobradio.com.br</a>.
		</div>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import {
	Dialog,
	Button,
	Input,
	Tabs,
	TabPane,
	Upload,
	TimeSelect,
	Table,
	Progress,
	TableColumn,
	Step,
	Steps
} from "element-ui";
import axios from "axios";
import models from "../../../routes/models";
import services from "../../../routes/services";
import selectRadio from "../../../utils/events/select-radio";
import jwt_decode from "jwt-decode";
const CancelToken = axios.CancelToken;

export default {
	data: () => ({
		radio: jwt_decode(localStorage.getItem("APP_01")).radio,
		dataDirectory: [],
		dialogOpenDirectory: false,
		dialogOpenFile: false,
		panelSteps: false,
		panelStepsUpdate: false,
		dialogTitle: "",
		active: 0,
		activeName: "step0",
		activeNameUpdate: "step0",
		arrFiles: [],
		arrFilesUpload: [],
		dataId3: [],
		btnNextMetadata: false,
		progressUpload: 0,
		progressbar: false,
		progressMsg: "",
		showLayout: null,
		source: CancelToken.source(),
		requestStatus: false,
		blockMetadata: false,
		cloudCredentials: false
	}),
	components: {
		[Dialog.name]: Dialog,
		[Button.name]: Button,
		[Input.name]: Input,
		[Tabs.name]: Tabs,
		[TabPane.name]: TabPane,
		[Upload.name]: Upload,
		[TimeSelect.name]: TimeSelect,
		[TableColumn.name]: TableColumn,
		[Table.name]: Table,
		[Progress.name]: Progress,
		[Steps.name]: Steps,
		[Step.name]: Step
	},
	methods: {
		getFoldersAndFiles() {
			this.dataDirectory = [];
			this.arrFiles = [];
			this.arrFilesUpload = [];
			if (this.$refs.uploadDirectory !== undefined) {
				this.$refs.uploadDirectory.clearFiles();
			}
			if (this.$refs.upload !== undefined) {
				this.$refs.upload.clearFiles();
			}
			models()
				.get(`music/getMusicsPerRadio?appKey=${this.radio.key}`)
				.then(res => {
					if (res.data.length > 0) {
						for (let item of res.data) {
							if (item.folder !== null) {
								this.dataDirectory.push({
									id: item.id,
									title: item.folder.replace("/", ""),
									folder: item.folder.replace("/", ""),
									date: item.date,
									total: item.total
								});
							} else {
								this.dataDirectory.push({
									id: item.id,
									title: item.title,
									artist: item.artist,
									mp3Name: item.mp3_name,
									date: item.date,
									total: item.total
								});
							}
						}
					}
				})
				.catch(e => {
					console.log(e);
				});
		},
		newDirectory() {
			if (this.$refs.uploadDirectory !== undefined) {
				this.$refs.uploadDirectory.clearFiles();
			}
			this.arrFilesUpload = [];
			this.dataId3 = [];
			this.dialogTitle = "";
			Swal.fire({
				title: "Nome do diretório",
				input: "text",
				inputAttributes: {
					autocapitalize: "off"
				},
				showCancelButton: true,
				cancelButtonText: "Cancelar",
				confirmButtonText: "Confirmar",
				showLoaderOnConfirm: true
			}).then(result => {
				if (result.value) {
					this.dataDirectory.push({
						folder: result.value
					});
					this.editFile({ folder: result.value });
				}
			});
		},
		newFile() {
			if (this.$refs.upload !== undefined) {
				this.$refs.upload.clearFiles();
			}
			this.arrFilesUpload = [];
			this.dataId3 = [];
			this.dialogTitle = "";
			this.dialogOpenFile = true;
		},
		deleteMusicAndFolder(item, index) {
			this.dialogOpenDirectory = false;
			Swal.fire({
				title: "Atenção",
				html: `Deseja realmente remover o diretório "<b>${item.title}</b>"? Essa ação irá remover todas as músicas que existem dentro dessa pasta e não poderá ser desfeita.`,
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Confirmar",
				cancelButtonText: "Cancelar"
			}).then(result => {
				if (result.value) {
					const formData = new FormData();
					const folder = this.dialogTitle ? this.dialogTitle + "/" : "";
					formData.append("cloud_name", this.radio.cloud_name);
					formData.append("cloud_key", this.radio.cloud_key);
					formData.append("cloud_secret", this.radio.cloud_secret);
					formData.append("folderName", item.folder);

					if (item.folder) {
						let promises = [];
						models()
							.get(
								`music/getItensFolder?appKey=${this.radio.key}&folder=${item.folder}/`
							)
							.then(res => {
								if (res.data.length > 0) {
									Swal.fire({
										title: "Aguarde",
										html: "Removendo arquivo(s)...",
										onOpen: () => {
											Swal.showLoading();
										}
									});
									for (let item of res.data) {
										formData.append("originalname", item.mp3_name);
										models().delete(`music/deleteMusicsPerRadio?id=${item.id}`);
									}
									promises.push(
										new Promise((resolve, reject) => {
											services()
												.post("services/destroyFolder", formData)
												.then(res => {
													resolve(res);
													Swal.close();
												})
												.catch(e => {
													reject(e);
												});
										})
									);
									// }
								}
							});

						Promise.all(promises).then((resolve, reject) => {
							if (resolve) {
								Swal.close();
								this.dataDirectory.splice(index, 1);
							} else {
								this.panelSteps = false;
								Swal(
									"Erro",
									"Não foi possível remover o arquivo selecionado, por favor, tente novamante.",
									"error"
								);
								console.log(e);
							}
						});
					} else {
						Swal.fire({
							title: "Aguarde",
							html: "Removendo arquivo(s)...",
							onOpen: () => {
								Swal.showLoading();
							}
						});
						// Remove do Cloudinary
						services()
							.post("services/destroyFile", formData)
							.then(res => {
								// Remove do Banco de Dados
								models()
									.delete(`music/deleteMusicsPerRadio?id=${item.id}`)
									.then(res => {
										Swal.close();
										this.dataDirectory.splice(index, 1);
									})
									.catch(e => {
										this.panelSteps = false;
										Swal(
											"Erro",
											"Não foi possível remover o arquivo selecionado, por favor, tente novamante.",
											"error"
										);
										console.log(e);
									});
							})
							.catch(e => {
								Swal(
									"Erro",
									"Não foi possível remover o arquivo selecionado, por favor, tente novamante.",
									"error"
								);
								console.log(e);
							});
					}
				}
			});
		},
		deleteItemInFolder(item, index) {
			this.dialogOpenDirectory = false;
			Swal.fire({
				title: "Atenção",
				html: `Deseja realmente remover o arquivo "<b>${item.title}</b>"? Esta ação não poderá ser desfeita.`,
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Confirmar",
				cancelButtonText: "Cancelar"
			}).then(result => {
				if (result.value) {
					Swal.fire({
						title: "Aguarde",
						html: "Removendo arquivo(s)...",
						onOpen: () => {
							Swal.showLoading();
						}
					});
					const formData = new FormData();
					const folder = this.dialogTitle ? this.dialogTitle + "/" : "";
					formData.append("originalname", item.mp3Name);
					formData.append("folderName", item.folder);
					formData.append("cloud_name", this.radio.cloud_name);
					formData.append("cloud_key", this.radio.cloud_key);
					formData.append("cloud_secret", this.radio.cloud_secret);

					// Remove do Cloudinary
					services()
						.post("services/deleteItemInFolder", formData)
						.then(res => {
							// Remove do Banco de Dados
							models()
								.delete(`music/deleteMusicsPerRadio?id=${item.id}`)
								.then(res => {
									Swal.close();
									this.getFoldersAndFiles();
								})
								.catch(e => {
									this.panelSteps = false;
									Swal(
										"Erro",
										"Não foi possível remover o arquivo selecionado, por favor, tente novamante.",
										"error"
									);
									console.log(e);
								});
						})
						.catch(e => {
							Swal(
								"Erro",
								"Não foi possível remover o arquivo selecionado, por favor, tente novamante.",
								"error"
							);
							console.log(e);
						});
				} else {
					this.dialogOpenDirectory = true;
				}
			});
		},
		getFileToUpload(res, file) {
			this.arrFilesUpload = file;
		},
		async uploadFiles() {
			window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
			this.panelSteps = false;
			let promiseArray = [];
			let uploads = await this.uploadCloudinary();
			for (let item of uploads) {
				let formData = {
					appKey: this.radio.key,
					title: item.title,
					artist: item.artist,
					mp3Name: item.name,
					url: item.url,
					folder: item.folder
				};
				promiseArray.push(
					new Promise((resolve, reject) => {
						models()
							.post("music/insertMusicsPerRadio", formData)
							.then(res => {
								this.arrFilesUpload = [];
								this.dataId3 = [];
								this.arrFiles = [];
								this.dialogTitle = "";
								resolve(res.data);
							})
							.catch(e => {
								reject(e);
							});
					})
				);
			}
			Promise.all(promiseArray).then((resolve, reject) => {
				if (resolve) {
					this.progressUpload = 0;
					this.progressbar = false;
					this.requestStatus = false;
					this.getFoldersAndFiles();
				} else {
					console.log(reject);
					Swal(
						"Erro",
						"Não foi possivel completar as atualizações, por favor, tente novamente",
						"success"
					);
				}
			});
		},
		async uploadCloudinary() {
			let data = [];
			let count = 1;
			this.progressMsg = `Realizando upload 1/${this.arrFilesUpload.length}`;
			this.progressbar = true;
			this.requestStatus = true;
			for (let key in this.arrFilesUpload) {
				const formData = new FormData();
				const config = {
					headers: { "Content-Type": "multipart/form-data" }
				};
				const folder = this.dialogTitle ? this.dialogTitle + "/" : "";
				const fileOriginalName = this.arrFilesUpload[key].raw.name;
				const fileNewName = fileOriginalName.replace(/&/g, "e");

				formData.append("key", this.radio.key);
				formData.append("cloud_name", this.radio.cloud_name);
				formData.append("cloud_key", this.radio.cloud_key);
				formData.append("cloud_secret", this.radio.cloud_secret);
				formData.append("folderName", folder);
				formData.append(
					"attachments",
					this.arrFilesUpload[key].raw,
					fileNewName
				);

				await services()
					.post("services/UploadCloudMusic", formData, config)
					.then(res => {
						for (let item of this.dataId3) {
							if (item.mp3Name == this.arrFilesUpload[key].name) {
								data.push({
									name: this.arrFilesUpload[key].raw.name,
									title: item.title,
									artist: item.artist,
									url: res.data.secure_url,
									folder: folder
								});

								this.progressUpload = Math.round(
									(count * 100) / this.arrFilesUpload.length
								);
								count = count + 1;
								if (count <= this.arrFilesUpload.length) {
									this.progressMsg = `Realizando upload ${count}/${this.arrFilesUpload.length}`;
								}
							}
						}
					})
					.catch(e => {
						console.log(e);
					});
			}
			return data;
		},
		step(id) {
			switch (id) {
				case 0:
					this.activeName = "step0";
					this.dialogOpenDirectory = false;
					this.dialogOpenFile = false;
					this.panelSteps = false;
					this.dataId3 = [];
					let count = 1;

					if (this.arrFilesUpload.length > 0) {
						Swal.fire({
							title: "Aguarde",
							html: `Estamos carregando a(s) música(s)...<br/>Música 1/${this.arrFilesUpload.length}`,
							allowOutsideClick: false,
							onOpen: () => {
								Swal.showLoading();
							}
						});
						for (let item of this.arrFilesUpload) {
							const formData = new FormData();
							const config = {
								headers: { "Content-Type": "multipart/form-data" }
							};
							formData.append("attachments", item.raw);
							models()
								.post("services/metadata", formData, config)
								.then(res => {
									res.data.common.mp3Name = item.name;
									this.dataId3.push(res.data.common);
									count = count + 1;
									if (count <= this.arrFilesUpload.length) {
										Swal.getContent().innerHTML = `<div style='text-align:center;'>Estamos carregando a(s) música(s)...<br/>Música ${count}/${this.arrFilesUpload.length}</div>`;
									} else {
										Swal.close();
										this.panelSteps = true;
									}
								})
								.catch(e => {
									console.log(e);
								});
						}
					} else {
						for (let item of this.arrFiles) {
							this.dataId3.push({
								id: item.id,
								mp3Name: item.mp3Name,
								title: item.title,
								artist: item.artist
							});
						}
						this.panelSteps = true;
					}
					break;
				case 1:
					this.activeName = "step0";
					// this.validateEditForm()
					break;
				case 2:
					this.activeName = "step2";
					break;
			}
		},
		editFile(item, index) {
			this.dataId3 = [];
			this.active = 0;
			if (item.folder) {
				this.dialogOpenDirectory = true;
				this.arrFiles = [];
				this.dialogTitle = item.folder;
				models()
					.get(
						`music/getItensFolder?appKey=${this.radio.key}&folder=${item.folder}/`
					)
					.then(res => {
						if (res.data.length > 0) {
							for (let item of res.data) {
								this.arrFiles.push({
									id: item.id,
									mp3Name: item.mp3_name,
									title: item.title,
									artist: item.artist,
									folder: item.folder,
									created: new Date(item.created).toLocaleString("pt-BR")
								});

								// this.dataId3.push({
								//     id: item.id,
								//     mp3Name: item.mp3_name,
								//     title: item.title,
								//     artist: item.artist
								// })
							}
						}
					})
					.catch(e => {
						console.log(e);
					});
			} else {
				this.panelStepsUpdate = true;
				this.activeNameUpdate = "step0";
				this.dataId3.push({
					id: item.id,
					mp3Name: item.mp3Name,
					title: item.title,
					artist: item.artist
				});
			}
		},
		updateFiles(obj) {
			this.panelSteps = false;
			this.panelStepsUpdate = false;
			Swal.fire({
				title: "Aguarde",
				html: "Atualizando informações...",
				onOpen: () => {
					Swal.showLoading();
				}
			});
			if (this.arrFilesUpload.length > 0) {
				let promisseArray = [];
				for (let item of obj) {
					this.panelSteps = false;
					this.panelStepsUpdate = false;
					if (item.id) {
						let formData = {
							id: item.id,
							title: item.title,
							artist: item.artist
						};

						promisseArray.push(
							new Promise((resolve, reject) => {
								models()
									.post("music/updateFileById", formData)
									.then(res => {
										resolve(res);
									})
									.catch(e => {
										reject(e);
									});
							})
						);
					}
				}
				Promise.all(promisseArray).then(() => {
					Swal.close();
					this.uploadFiles();
				});
			} else {
				for (let item of obj) {
					let formData = {
						id: item.id,
						title: item.title,
						artist: item.artist
					};
					models()
						.post("music/updateFileById", formData)
						.then(res => {
							this.panelSteps = false;
							this.panelStepsUpdate = false;
							Swal.close();
							this.progressUpload = 0;
							this.progressbar = false;
							this.getFoldersAndFiles();
						})
						.catch(e => {
							this.panelSteps = false;
							this.panelStepsUpdate = false;
							Swal(
								"Erro",
								"Falha ao processar as informações, por favor, tente novamente.",
								"error"
							);
							console.log(e);
						});
				}
			}
		},
		validateEditForm() {
			for (let item of this.dataId3) {
				if (!item.title || !item.artist) {
					this.blockMetadata = true;
				} else if (item.title == "" || !item.artist == "") {
					this.blockMetadata = true;
				} else {
					this.blockMetadata = false;
				}
			}
		},
		next() {
			if (this.active++ > 2) this.active = 0;
		}
	},
	mounted() {
		let self = this;
		this.getFoldersAndFiles();
		selectRadio.$on("selectRadio", payload => {
			self.radio.key = payload.key;
			self.radio.cloud_key = payload.cloud_key;
			self.radio.cloud_name = payload.cloud_name;
			self.radio.cloud_secret = payload.cloud_secret;
			self.getFoldersAndFiles();
		});
	},
	watch: {
		dataId3: {
			handler: function(after, before) {
				for (let item of before) {
					if (item.title && item.artist) {
						this.blockMetadata = false;
					} else {
						this.blockMetadata = true;
						break;
					}
				}
			},
			deep: true
		}
	}
};
</script>

<style>
.row-btn-edit-playlist {
	margin-right: 10px;
}
.row-folder {
	cursor: pointer;
}
.btn-actions {
	cursor: pointer;
}
.el-dialog__body {
	padding-top: 0px !important;
}
.btn-footer {
	margin-top: 20px;
	float: right;
}
.el-input.el-input--mini {
	margin-top: -15px;
}
.el-input.el-input--mini.is-disabled {
	margin-top: -15px;
}
.el-steps.el-steps--simple {
	align-items: baseline !important;
}
.el-step.is-simple {
	align-items: flex-start !important;
}
.el-step__icon-inner.fas {
	font-size: 14px !important;
	margin-top: 3px !important;
}
#btn-action-times {
	margin-left: 5px;
}
@media screen and (max-width: 859px) {
	.mobile-none {
		display: none;
	}
}
@media screen and (min-width: 860px) {
	.desktop-on {
		display: none;
	}
}
</style>
